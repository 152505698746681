import css from 'styled-jsx/css'

export const FormSectionStyle = css.global`
  .form-section {
    padding: 80px 0;
    background-color: #f8fcff;
  }
  .form-section .left .heading {
    font-size: 36px;
    line-height: 46px;
    color: #262626;
    font-weight: 500;
    margin-bottom: 48px;
  }
  .form-section .left .step-wrapper .step {
    display: flex;
  }
  .form-section .left .step-wrapper {
    display: flex;
  }
  .form-section .left .step {
    display: flex;
    flex-direction: column;
    margin-right: 16px;
  }
  .form-section .left .step .step-number {
    box-shadow: 0 2px 16px 0 rgba(24, 144, 255, 0.17);
    background-color: #fff;
    width: 40px;
    height: 40px;
    font-size: 20px;
    font-weight: 500;
    color: #1890ff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form-section .step .divider-step {
    height: 106px;
    border-left: 1px dashed #91d5ff;
    margin-left: 19px;
    position: relative;
  }
  .form-section .left .content .title-left {
    font-size: 20px;
    color: #262626;
    font-weight: 500;
    margin-bottom: 8px;
    text-align: left;
  }
  .form-section .left .content .des-left {
    font-size: 16px;
    color: #595959;
    text-align: left;
  }
  .form-section .right .title-right {
    font-size: 24px;
    line-height: 32px;
    color: #262626;
    white-space: break-spaces;
    font-weight: bold;
    text-align: center;
    margin-bottom: 24px;
  }
  .form-section .register-form {
    box-shadow: 0 2px 16px 0 rgba(24, 144, 255, 0.17);
  }
  body .form-section .register-form .ant-form-item-label {
    padding: 2px 0;
  }
  @media screen and (max-width: 768px) {
    .form-section {
      padding: 40px 0;
    }
    .form-section .left .heading {
      font-size: 28px;
      text-align: center;
      line-height: 35px;
      margin-bottom: 24px;
    }
    .form-section .left .content .title-left {
      font-size: 18px;
    }
    .form-section .left .content .des-left {
      font-size: 14px;
    }
    .form-section .step .divider-step {
      height: 88px;
    }
    body .form-section .register-form {
      padding: 24px 16px;
      border-radius: 20px;
      box-shadow: 0 2px 16px 0 rgba(24, 144, 255, 0.17);
      background-color: #fff;
    }
  }
`
